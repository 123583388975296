import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer class="footer-dark p-0 position-relative bg-black-pearl-blue-dark" id='contact'>
            <div class="pt-40px pb-40px bg-black-pearl-blue">
                <div class="container position-relative">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-8 col-md-10 icon-with-text-style-08 md-mb-20px">
                            <div class="feature-box feature-box-left-icon-middle overflow-hidden">
                                <div
                                    class="feature-box-icon feature-box-icon-rounded w-90px h-90px rounded-circle border border-2 border-color-transparent-white-light me-30px">
                                    <img src={require('../../assets/image/prarthana-home-icon.webp')} class="w-40px h-40px" alt="" />
                                </div>
                                <div class="feature-box-content last-paragraph-no-margin">
                                    <h5 class="d-inline-block fw-700 text-white mb-0">Follow Us.</h5>
                                    <p>Trusted by over 50,000 worldwide users since 2020.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 text-center text-lg-end">
                            <ul className='social_media_box'>
                                <li><a href='https://www.facebook.com/praarthana.app.1/' target='_blank'><i class="icon feather icon-feather-facebook icon-small text-medium-gray"></i></a></li>
                                <li><a href='https://www.instagram.com/praarthana_app/' target='_blank'><i class="icon feather icon-feather-instagram icon-small text-medium-gray"></i></a></li>
                                <li><a href='https://www.youtube.com/@praarthana_app' target='_blank'><i class="icon feather icon-feather-youtube icon-small text-medium-gray"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center pt-6 sm-pt-40px">

                    <div class="col-lg-4 col-md-4 col-sm-6 md-mb-30px text-center footer_col text-sm-start">
                        {/* <Link to="/" class="footer-logo mb-15px d-inline-block">
                            <img src="./image/prarthna_logo.png"
                                alt="" />
                        </Link> */}
                        {/* <p>Discover the ultimate devotional companion with the Praarthana app.</p>
                        <div
                            class="row align-items-center border border-color-transparent-white-light g-0 border-radius-4px mt-30px lg-mt-25px">
                            <div class="col-5 text-center">
                                <h3 class="text-white fw-700 mb-0">4.8</h3>
                            </div>
                            <div
                                class="col-7 border-start border-color-transparent-white-light ps-20px pe-20px pt-15px pb-15px lg-ps-15px lg-pe-15px text-center text-sm-start">
                                <div class="review-star-icon fs-14 lh-30">
                                    <i class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i
                                        class="fa-solid fa-star"></i><i class="fa-solid fa-star"></i><i
                                            class="fa-solid fa-star"></i>
                                </div>
                                <span class="d-block fs-14 text-white lh-20 fw-500">300 Reviews</span>
                            </div>
                        </div> */}
                        <div>
                            {/* <span class="d-block text-white fw-600 mb-10px">About</span> */}

                            <ul>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/terms">Terms</Link></li>

                            </ul>

                        </div>
                    </div>
                    {/* <div class="col-lg-4 col-md-4 col-sm-5  md-mb-30px pl-5 footer-column-2">
                        <div>
                            <span class="d-block text-white fw-600 mb-10px">About</span>

                            <ul>
                                <li><Link to="/blog">Blog</Link></li>
                                <li><Link to="/privacy">Terms & Privacy Policy</Link></li>
                                <li><Link to="/refunds-and-cancellation-policy">Refunds / Cancellation Policy</Link></li>
                                <li><Link to="/pricing">Pricing</Link></li>
                                <li><Link to="/download">Download</Link></li>
                            </ul>

                        </div>
                    </div> */}
                    <div class="col-lg-4 col-md-4 col-sm-5  md-mb-30px pl-5 footer-column-2 footer_col">
                        <div>
                            <ul>
                                <li><Link to="/privacy">Privacy Policy</Link></li>
                                <li><Link to="/refunds-and-cancellation-policy">Refunds / Cancellation Policy</Link></li>
                                <li><Link to="/pricing">Pricing</Link></li>
                                <li><Link to="/download">Download</Link></li>
                            </ul>
                        </div>
                        {/* <div>

                            <span class="d-block text-white fw-600 mb-10px">DIGIFISH MEDIA PRIVATE LIMITED</span>
                            <p><strong>032, 4th Floor, Emerald Plaza,
                                Sector-65, Gurugram,
                                Haryana-122018</strong></p>
                            <span class="d-block text-white fw-600 mb-10px">RAJMITH</span>
                            <p><strong>B-24, 3rd Floor, Palam Vypar Kendra, Palam Vihar, Gurgaon, Haryana</strong></p>
                        </div> */}
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-5 offset-md-0  order-5 order-md-3 footer_col footer-column-3 xs-mb-30px">
                        <div>
                            <span class="d-block text-white fw-600 mb-10px">Contact Us</span>
                            <span class="d-block">Whatsapp directly?</span>
                            <a href="tel:919990509933" class="text-white fw-600 mb-15px d-inline-block footer-link-box"><img className='' src={require('../../assets/image/whatsapp.png')} /> +91 999 050 9933</a>
                            <span class="d-block">Need live support?</span>
                            <a href="mailto:hi@praarthana.app" class="text-white fw-600 d-inline-block footer-link-box"><img className='' src={require('../../assets/image/email.png')} /> hi@praarthana.app</a>
                        </div>
                    </div>


                </div>
                <div class="row justify-content-center align-items-center pt-5 md-pt-30px">
                    <div class="col-12">
                        <div class="divider-style-03 divider-style-03-01 border-color-transparent-white-light"></div>
                    </div>
                    <div
                        class="col-xl-8 col-lg-9 pt-30px pb-30px fs-14 lh-26 last-paragraph-no-margin text-center order-2 order-sm-1">
                        Copyright © 2024 Praarthana.app | All rights reserved.
                    </div>
                </div>
            </div>
        </footer>
    )
}
