import React, { useEffect, useRef, useState } from 'react';
import './style.css'
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { EffectCards, Autoplay, Pagination } from 'swiper/modules';
import { toast } from 'react-toastify';
import { get } from '../../helpers/helper_api';
import useMasonry from "./use-masonry";
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../component/Loader';
import { Helmet } from 'react-helmet-async';
import bgImage from "../../assets/images/download_banner.jpg";
import moment from "moment";
// import VideoModal from '../../component/VideoModal';


export default function Blog(props) {
	const [randomBlog, setRandomBlog] = useState([]);
	const [loader, setLoader] = useState(true);
	const [freshStories, setFreshStories] = useState([]);
	const [trending, setTrending] = useState([]);
	const [tags, setTags] = useState([]);
	const [videos, setVideos] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const [mainBanner, setMainBanner] = useState(null);
	const [headerData, setHeaderData] = useState(null);
	const [isUnlikeModal, setIsUnlikeModal] = useState(false);
	const [shareData, setShareData] = useState(null);
	const [ads, setAds] = useState([]);
	// const [random, setRandom] = useState(8)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const { categories } = useMasonry(
		randomBlog,
		".portfolio-list",
		".masonry-grid",
		".messonry-button",
		".messonry-button button"
	);

	// console.log('homerandomBlog', homerandomBlog)

	// useEffect(() => {
	// 	console.log(props?.headerData);
	// 	if (props?.headerData)
	// 		setHeaderData(props?.headerData);
	// }, [props?.headerData])

	useEffect(() => {
		get("blog/web")
			.then(res => {
				if (res?.statusCode == 200) {
					setRandomBlog(res?.data?.random);
					setFreshStories(res?.data?.freshStories);
					setTrending(res?.data?.trending);
				} else {
					toast.error("We are having a problem to load the blog, Please Try again later!")
				}
			})
			.catch(err => {
				console.log('error while getting blogs', err);
				toast.error("We are having a problem to load the blog, Please Try again later!")
			})
		get("blog/category/web", {})
			.then(res => {
				if (res?.statusCode == 200) {
					setAllCategories(res?.data);
					setLoader(false);
				} else {
					setLoader(false);
					toast.error("We are having a problem to load the blog, Please Try again later!")
				}
			})
			.catch(err => {
				console.log('error while getting blogs', err);
				setLoader(false);
				toast.error("We are having a problem to load the blog, Please Try again later!")
			})
		get("tags/web")
			.then(json => {
				if (json?.statusCode == 200) {
					// console.log('response from get blog list', json?.data);
					setTags(json?.data);
				} else {
					toast.error(json?.error);
				}
			})
			.catch(error => {
				console.log('error while getting project list', error);
			})
	}, []);

	const compressId = (id) => {
		const temp = id.slice(id.length - 4, id.length);
		// console.log('compressing id', temp);
		return temp;
	}

	const parseName = (str) => {
		if (str) {
			let temp = str.split(",").join("");
			return temp.split(" ").join("-").toLowerCase();
		} else
			return "";
	}

	const openBlog = (item) => {
		if (item?._id)
			return "/blog/" + parseName(item?.link);
	}

	const openExp = (item) => {
		return '/experience/' + compressId(item?._id) + "/" + "top-travel-destinations-in-" + parseName(item?.name);
	}

	const openWho = (item) => {
		return '/who/' + compressId(item?._id) + "/" + "top-travel-destinations-for-" + parseName(item?.name)
	}

	const openCat = (item) => {
		if (item?._id)
			return `/blogs/category/${parseName(item?.title)}`
	}

	const openTag = (item) => {
		if (item?._id)
			return `/blogs/tag/${parseName(item?.title)}`
	}

	useEffect(() => {
		let added = document.getElementById("2368669");
		if (added)
			added.remove();

		// setLoading(false);
		setTimeout(() => {
			let script = document.createElement("script");
			script.type = "text/javascript";
			script.id = "2368669";
			script.async = true;
			script.function = ((d, sc, u) => {
				var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0];
				// console.log('getting element', s, p)
				s.type = 'text/javascript';
				s.async = true;
				s.src = u + '?v=' + (+new Date());
				p.parentNode.insertBefore(s, p);
			})(document, 'script', '//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js');
			document.body.appendChild(script);

		}, 500);
	}, []);

	useEffect(() => {
		let added = document.getElementById("2312218");
		if (added)
			added.remove();

		// setLoading(false);
		setTimeout(() => {
			let script = document.createElement("script");
			script.type = "text/javascript";
			script.id = "2312218";
			script.async = true;
			script.function = ((d, sc, u) => {
				var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0];
				// console.log('getting element', s, p)
				s.type = 'text/javascript';
				s.async = true;
				s.src = u + '?v=' + (+new Date());
				p.parentNode.insertBefore(s, p);
			})(document, 'script', '//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js');
			document.body.appendChild(script);

		}, 500);
	}, []);

	return (
		<>
			{
				loader && <Loader />
			}
			<Helmet>
				<title>Blogs | Praarthana</title>
				<meta name="keyword" content={"top blog for " + allCategories?.map((item) => (" " + item.title))} />
				<meta name="description" content="Looking for travel blog? Explore our tour and travel website and discover exciting destinations, breathtaking scenery, and unforgettable experiences. From tropical paradises to urban explorations, we offer a wide range of customizable packages to suit every budget and preference." />
				<meta name="dc.language" content="English" />
				<meta name="dc.source" content="http://www.woowdestinations.com" />
				<meta name="dc.title" content="Woow Destinations" />
				<meta name="dc.keywords" content={"top blog for " + allCategories?.map((item) => (" " + item.title))} />
				<meta name="dc.description" content="Looking for travel blog? Explore our tour and travel website and discover exciting destinations, breathtaking scenery, and unforgettable experiences. From tropical paradises to urban explorations, we offer a wide range of customizable packages to suit every budget and preference." />
				<meta name="category" content="Woow Destinations" />
				<meta name="robots" content="index, follow" />
				<meta property="og:locale" content="en-in" />
				<meta property="og:type" content="website" />
				<meta name="theme-color" content="#ffffff" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="canonical" href="http://www.woowdestinations.com" />
			</Helmet>
			<section class="page-title-big-typography pb-0 cover_background_home ipad-top-space-margin cover-background background-position-center-bottom position-relative half-section sm-py-0" style={{ backgroundImage: `url(${bgImage})` }}>

				<div class="container">
					<div class="row align-items-center justify-content-center extra-very-small-screen">
						<div class="col-lg-6 col-md-8 position-relative text-center page-title-extra-large" data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [-90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 900, "easing": "easeOutCirc" }'>
							<h2 class="mb-10px fw-500">Join the Praarthana application</h2>
							<h1 class="mb-0 text-dark-gray fw-700 ls-minus-2px">Blog</h1>
						</div>
					</div>
				</div>
			</section>
			<section className='pt-0'>
				<div className="container h-100 position-relative z-index-9 mt-0">
					<section className="top-images-section top_images_section_blog_main mt-0">
						<div className="container">
							<div className="top-images-box">
								<div className="row">
									{randomBlog?.map((item, index) => {
										if (index < 3)
											return (
												<div className="col-lg-4">
													<div className="news-post image-post">
														<img src={item?.image} alt={item?.title} />
														<div className="hover-post text-center">
															{/* <div  className="category-link list_">{item?.category?.map((item)=>{
															return(
																<Link className='text_link_'>{item?.title}</Link>
															)
														})}</div> */}
															<h2><Link to={openBlog(item)} >{item?.title}</Link></h2>
															<div className="category-link mt-2">
																<ul className="post-tags">
																	<li><Link target='_blank' to={openCat(item?.category[0])}>{item?.category[0]?.title}</Link></li>
																	<li>{moment(item?.updatedAt).format("MMM DD, YYYY")}</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											)
									})}
								</div>
							</div>
						</div>
					</section>
					<section className="fresh-section pt-0">
						<div className="container">
							<div className="title-section text-center">
								{/* <h1></h1> */}
								<div className="rlr-section-header rlr_section_header_home_page" style={{ justifyContent: 'center' }}>
									{/* <!-- Section heading --> */}
									<div className="rlr-section__title">
										<h2 className="rlr-section__title--main text-center">Fresh Stories</h2>
									</div>
								</div>
							</div>
							{freshStories?.length ?
								<div className="fresh-box">
									<Swiper
										slidesPerView={1}
										spaceBetween={10}
										// pagination={true} 
										breakpoints={{
											640: {
												slidesPerView: 2,
												spaceBetween: 10,
											},
											768: {
												slidesPerView: 2.4,
												spaceBetween: 10,
											},
											1024: {
												slidesPerView: 4,
												spaceBetween: 10,
											},
										}}


										autoplay={{
											delay: 2500,
											disableOnInteraction: false,
										}}
										modules={[Autoplay, Pagination]}
										loop={true}
										className="mySwiper"
									>
										{freshStories?.map((item, index) => {
											return (
												<SwiperSlide>
													<div className="item p-0">
														<div className="news-post standard-post">
															<div className="image-holder">
																<Link target='_blank' to={openBlog(item)}><img src={item?.image} alt={item?.title} /></Link>
															</div>
															<h2><Link target='_blank' to={openBlog(item)}>{item?.title}</Link></h2>
															<ul className="post-tags">
																<li>{item?.category[0]?.title}</li>
																<li>{moment(item?.updatedAt).format("MMM DD, YYYY")}</li>
															</ul>
														</div>
													</div>
												</SwiperSlide>
											)
										})}

									</Swiper>
									{/* <OwlCarousel className='owl-theme blog_one_slider'
										loop
										margin={5}
										nav
										responsive={{
											0: {
												items: 1,
											},
											600: {
												items: 1.5,
											},
											1000: {
												items: 4,
											},
										}}
									>
										{freshStories?.map((item, index) => {
											return (
												<div className="item">
													<div className="news-post standard-post">
														<div className="image-holder">
															<Link target='_blank' to={openBlog(item)}><img src={item?.image} alt={item?.title} /></Link>
														</div>
														<h2><Link target='_blank' to={openBlog(item)}>{item?.title}</Link></h2>
														<ul className="post-tags">
															<li>{item?.category[0]?.title}</li>
															<li>{moment(item?.updatedAt).format("MMM DD, YYYY")}</li>
														</ul>
													</div>
												</div>
											)
										})}
									</OwlCarousel> */}
								</div>
								: null}
							<div className="border-bottom"></div>
						</div>
					</section>
				</div>
			</section>
			<section className="trending-section pt-0">
				<div className="container">
					<div className="title-section">
						<div className="row">
							<div className="col-md-5">
							</div>
							<div className="title-section">
								<div className="rlr-section-header rlr_section_header_home_page">
									<div className="rlr-section__title">
										<h2 className="rlr-section__title--main text-center">Trending Posts</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="trending-box">
						<div className="row">
							{trending?.length ?
								<div className="col-lg-5">
									<div className="owl-wrapper">
										<div className="news-post image-post trending_posts_section">
											<img src={trending[0]?.image} alt={trending[0]?.title} />
											<div className="hover-post overlay-bg">
												<h2><Link target='_blank' to={openBlog(trending[0])}>{trending[0]?.title}</Link></h2>
												<ul className="post-tags">
													<li><Link target='_blank' to={openCat(trending[0]?.category[0])}>{trending[0]?.category[0]?.title}</Link></li>
													{/* <li>{trending[0]?.reviews?.length} comments</li> */}
													<li>{moment(trending[0]?.updatedAt).format("MMM DD, YYYY")}</li>
												</ul>
												<p>{trending[0]?.title?.length > 150 ? trending[0]?.title?.substring(0, 150) + "..." : trending[0]?.title}</p>
											</div>
										</div>
									</div>
								</div>
								: null}
							<div className="col-lg-7 col-md-6">
								<ul className="posts-list posts_list_new">
									{trending?.map((item, index) => {
										if (index > 0)
											return (
												<li className='list_'>
													<h2><Link target='_blank' to={openBlog(item)}>{item?.title?.length > 80 ? item?.title?.substring(0, 80) + "..." : item?.title}</Link></h2>
													<ul className="post-tags mb-2">
														<li>{moment(item?.updatedAt).format("MMM DD, YYYY")}</li>
														{/* <li>{item?.reviews?.length} comments</li> */}
													</ul>
													<Link target='_blank' className="text-link text_link_" to={openCat(item?.category[0])}>{item?.category[0]?.title}</Link>
													{/* {
														item?.tags?.map((item1) => {
															return (
																<Link target='_blank' className="text-link text_link_" to={openCat(item1)}>{item1?.title}</Link>
															)
														})
													} */}
												</li>
											)
									})}

								</ul>
							</div>
							{/* <div className="col-lg-4 col-md-6 d-none">
										<div className="news-post image-post image_post_new">
											<ins class="bookingaff" randomBlog-aid="2368669" randomBlog-target_aid="2368669" randomBlog-prod="dfl2" randomBlog-width="300" randomBlog-height="450" randomBlog-lang="en">

												<a href="//www.booking.com?aid=2368669">Booking.com</a>
											</ins>

										</div>
									</div> */}
						</div>
					</div>
				</div>
			</section>
			{/* <section id="features" className="rlr-section rlr-section__mb landding__plugin mb-0">
				<div className="container">
					{ads.map((item) => {
						if (item?.position == 'top')
							return (
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<a href={item?.utm} target='_blank'><img src={item?.banner} style={{ width: 'auto', height: 'auto' }} alt={item?.banner} /></a>
								</div>
							)
					})}
				</div>
			</section> */}

			<section className="blog-section blog_section_post mt-0">
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
							<div className="blog-box ">
								<div className="title-section">

									<div className="title-section">
										{/* <h1></h1> */}
										<div className="rlr-section-header rlr_section_header_home_page">
											{/* <!-- Section heading --> */}
											<div className="rlr-section__title">
												<h2 className="rlr-section__title--main ">Latest Posts</h2>
											</div>
										</div>
									</div>
								</div>
								<div className="row row-cols-1 row-cols-sm-1 row-cols-lg-2 row-cols-xl-2 portfolio-list mb-n30">
									{freshStories.length &&
										freshStories.map((item, index) => {
											if (index < 8)
												return (
													<div
														key={item.id}
														// eslint-disable-next-line react/no-unknown-property
														group={`any`}
														className={`col masonry-grid mb-30 `}
													>
														<div className="item ">
															<div className="news-post article-post">
																<div className="image-holder">
																	<img src={item?.image} alt={item?.title} />
																</div>
																<div className='list_'>
																	{
																		item?.category?.map((item1) => {
																			return (
																				<Link target='_blank' className="text-link text_link_ hyper" to={openCat(item1)}>{item1?.title}</Link>
																			)
																		})
																	}
																</div>
																<h2><Link target='_blank' to={openBlog(item)}>{item?.title}</Link></h2>
																<ul className="post-tags">
																	<li>{moment(item?.updatedAt).format("MMM DD, YYYY")}</li>
																	{/* <li>{item?.reviews?.length} comments</li> */}
																	{item?.tags?.map((tag) => (
																		<li><Link target="_blank" to={openTag(tag)}>{tag?.title}</Link></li>
																	))}
																</ul>
																<p>{item?.title?.length > 100 ? item?.title.substring(0, 100) : item?.title}...</p>
															</div>
														</div>
													</div>
												)
										}
										)}


								</div>
								<div className="center-button">
									<Link target='_blank' className="button-one" to="/blogs">Load More</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 mt-1">
							<div className="sidebar sidebar_new">
								<div className="widget social-widget">
									<h2>Social</h2>
									<ul className="social-list">
										<li>
											<a href="https://www.facebook.com/praarthana.app.1/" target='_blank'>
												<i className="fab fa-facebook"></i>
												facebook
											</a>
										</li>
										<li>
											<a href="https://www.youtube.com/@praarthana_app" target='_blank'>
												<i class="fa-brands fa-youtube"></i>
												Youtube
											</a>
										</li>
										<li>
											<a href="https://www.instagram.com/praarthana_app/" target='_blank'>
												<i className="fab fa-instagram"></i>
												instagram
											</a>
										</li>
									</ul>
								</div>
								{mainBanner?.length ?
									<div className="widget features-widget">
										<div className="owl-wrapper">
											{/* <OwlCarousel className='owl-theme blog_four_slider blog_two_slider_white_dots'
												loop
												margin={5}
												dots
												responsive={{
													0: {
														items: 1,
													},
													600: {
														items: 1,
													},
													1000: {
														items: 1,
													},
												}}>
												{
													mainBanner?.map((item, index) => {
														return (
															<div className="item">
																<div className="news-post image-post image_post_home_pro">
																	<img src={item?.banner} alt={item?.name} />
																	<div className="hover-post ">
																		<div><Link className="category-link" >{item?.location} | {item?.country[0].name}</Link></div>
																		<h2><Link >{item?.name}</Link></h2>
																		<p>{item?.overview?.length > 80 ? item?.overview?.slice(0, 90) + "..." : item?.overview}</p>
																		<ul className="post-tags">
																			<li><Link >3 comments</Link></li>
																			<li>{item?.nights}</li>
																		</ul>
																	</div>
																</div>
															</div>
														)
													})
												}
											</OwlCarousel> */}
										</div>
									</div>
									: null}
								<div className='right_sidebar'>
									<div className="widget category-widget">
										<h2>Categories</h2>
										<ul className="category-list list_posts_tag">
											{
												allCategories?.map((item, index) => {
													return (
														<Link target='_blank' to={openCat(item)}>
															{item?.title}
														</Link>
													)
												})
											}
										</ul>
									</div>
									<div className="widget list-widget">
										<h2>Tags</h2>
										<ul className="list-posts list_posts_tag">
											{
												tags?.map((item, index) => {
													return (
														<Link target='_blank' className="text-link" to={openTag(item)}>{item?.title}</Link>
													)
												})
											}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}